import { IProductConfigData } from 'ui/component/product-configurator/product-config';
import { isVirtualDoubleProduct } from 'ui/component/product-configurator/product-config/bundle-config/util';
import {
    IBundleOptionData,
    IBundleSelectionData,
} from 'ui/component/product-configurator/product-config/bundle-config/bundle-option';

import { getOptionValue } from 'ui/util/get-option-value';

import ICoreConfigInfo = Magento.Definitions.SilkRestappDataConfigInfoInterface;

interface ILiquidPixelBaseConfigObj {
    baseUrl: string;
    scaleFactor: number;
    bundleID: string;
    prodID: string;
    prodColor: string;
    colorize: string;
    prodView: string;
    previewSize: string;
    seed: string;
    call: string;
    sink: string;
}

interface IProductAttribute {
    code: string;
    label: string;
    value: any;
    'backend_value': any;
}

interface IProductSelectionColorize {
    [key: number]: {
        isColorize: boolean;
    };
}

interface ISelectedProducts {
    [key: number]: (IBundleSelectionData | IConfigurableSelectionData | IDummySelectionData)[];
}

interface IUsedProductAttribute {
    enableLiquidPixelGallery: boolean;
    liquidPixelGalleryView: string[];
    liquidPixelSeed: string;
}

interface ILiquidPixelConfigProps {
    productConfig: IProductConfigData;
    coreConfig: ICoreConfigInfo;
}

interface IConfigurableSelectionData {
    'type_id': string;
    sku: string;
    color: string;
}

enum EProductType {
    'dummy' = 'dummy',
    'empty' = 'empty',
}

interface IDummySelectionData extends IConfigurableSelectionData {
    'type_id': EProductType;
}

interface ILiquidPixelImage {
    type: 'image';
    'product_id': string;
    thumb: string;
    full: string;
    image: string;
}

interface ILiquidPixelBase {
    getAllAvailableImages: () => ILiquidPixelImage[] | [];
}

export class LiquidPixelBase implements ILiquidPixelBase {
    protected config: ILiquidPixelBaseConfigObj;

    protected selectedProducts: ISelectedProducts = {};

    protected productAttribute: IUsedProductAttribute = {
        enableLiquidPixelGallery: false,
        liquidPixelGalleryView: [],
        liquidPixelSeed: '001',
    };

    protected productType: string;

    protected productSelectionColorize: IProductSelectionColorize = {};

    private readonly neutralColor: string = 'NEUTRAL';

    private readonly nullSku: string = 'NULL';

    private readonly enableLiquidPixelGallery: string = 'enable_liquid_pixel_gallery';

    private readonly liquidPixelGalleryView: string = 'liquid_pixel_gallery_view';

    private readonly liquidPixelSeed: string = 'liquid_pixel_seed';

    private readonly props: ILiquidPixelConfigProps;

    public constructor(props: ILiquidPixelConfigProps) {
        this.props = props;
        this.initConfig();
        this.setSelectedProducts();
        this.setProductsSelectionsColorize();
        this.generateLiquidPixelParam();
    }

    private static extractColorCode(productColor: string) {
        const matches = productColor.match(/\(([\w]+)\)/);
        if (!matches || matches.length < 2) {
            return productColor;
        }

        return matches[1];
    }

    public getAllAvailableImages(): ILiquidPixelImage[] {
        if (
            !this.productAttribute.enableLiquidPixelGallery ||
            !this.isPossibleToGenerateUrl() ||
            !this.productAttribute.liquidPixelGalleryView?.length
        ) {
            return [];
        }

        try {
            return this.prepareImagesByView();
        } catch (e) {
            return [];
        }
    }

    private prepareImagesByView(): ILiquidPixelImage[] {
        const images: ILiquidPixelImage[] = [];
        const previewUrlSetParam = Object.entries(this.config).map(([key, value]) => {
            if (!['call', 'sink', 'seed', 'baseUrl'].includes(key) && value !== '') {
                return `${key}[${value}]`;
            }
            return '';
        }).filter(Boolean).join(',');
        const previewUrlGlobalParam = Object.entries(this.config).map(([key, value]) => {
            if (['call', 'sink', 'seed'].includes(key)) {
                return `${key}=${value}`;
            }
            return '';
        }).filter(Boolean).join('&');

        this.productAttribute.liquidPixelGalleryView.forEach((prodView) => {
            const previewThumbParam = [previewUrlSetParam, `prodView[${prodView}]`, 'previewSize[small]'].join(',');
            const previewFullParam = [previewUrlSetParam, `prodView[${prodView}]`, 'previewSize[medium]'].join(',');
            const previewImageParam = [previewUrlSetParam, `prodView[${prodView}]`, 'previewSize[medium]'].join(',');
            const img: ILiquidPixelImage = {
                type: 'image',
                product_id: this.props.productConfig?.simpleProduct?.product_id,
                thumb: this.config.baseUrl + [previewThumbParam, previewUrlGlobalParam].join('&'),
                full: this.config.baseUrl + [previewFullParam, previewUrlGlobalParam].join('&'),
                image: this.config.baseUrl + [previewImageParam, previewUrlGlobalParam].join('&'),
            };
            images.push(img);
        });

        return images;
    }

    private generateLiquidPixelParam() {
        if (!this.isPossibleToGenerateUrl()) {
            return;
        }
        if (this.selectedProducts && Object.keys(this.selectedProducts).length > 0) {
            let index = 0;
            const lastIndex = Object.keys(this.selectedProducts).length - 1;
            Object.keys(this.selectedProducts).forEach((id) => {
                const productGroup: IBundleSelectionData[] | IConfigurableSelectionData[] | IDummySelectionData[] =
                    this.selectedProducts[Number(id)];
                // check if multiple color options can be selected for this product group or not.
                const isColorize = this.productSelectionColorize[Number(id)]?.isColorize;

                productGroup.forEach((
                    product: IBundleSelectionData | IConfigurableSelectionData | IDummySelectionData, index,
                ) => {
                    // set product id / bundle id,  colorize
                    this.setProductOptions(product, isColorize, index);
                });

                if (index !== lastIndex) {
                    if (this.isBundleProduct()) {
                        this.config.bundleID += isColorize ? '-' : '|';
                    } else {
                        this.config.prodID += '|';
                    }
                }

                // set product color
                this.setProductColor(
                    !isColorize || (
                        productGroup[0].type_id === EProductType.empty || productGroup[0].type_id === EProductType.dummy
                    ) ? LiquidPixelBase.extractColorCode(productGroup[0].color) : this.neutralColor,
                );
                index += 1;
            });
        }
    }

    /**
     * Check if options are selected or not
     */
    private isPossibleToGenerateUrl() {
        const { productConfig } = this.props;
        return (productConfig.selections &&
            Object.keys(productConfig.selections).length &&
            !Object.values(productConfig.selections).some(value => value === '' || (Array.isArray(value) && value.length === 0))) ||
            (productConfig.selectedProductNames && Object.keys(productConfig.selectedProductNames).length);
    }

    /**
     * Initialize liquid pixel base configuration
     */
    private initConfig() {
        this.initProductAttribute();
        const { coreConfig } = this.props;
        this.config = {
            baseUrl: coreConfig.extension_attributes?.liquid_pixel_base_url ?? '',
            scaleFactor: 3,
            bundleID: '',
            prodID: '',
            prodColor: '',
            prodView: '',
            seed: this.productAttribute.liquidPixelSeed,
            call: 'url[file:main]',
            sink: 'format[jpg]',
            colorize: '',
            previewSize: '',
        };
        this.productType = this.props.productConfig?.product?.type ?? '';
        this.selectedProducts = [];
        this.productSelectionColorize = {};
    }

    /**
     * Fetch all the required product attributes
     */
    private initProductAttribute() {
        const attributes = this.props.productConfig?.product?.attributes || {};

        attributes.forEach(({ code, backend_value: backendValue }: IProductAttribute) => {
            if (code === this.enableLiquidPixelGallery) {
                this.productAttribute.enableLiquidPixelGallery = Boolean(backendValue);
            } else if (code === this.liquidPixelGalleryView && backendValue) {
                this.productAttribute.liquidPixelGalleryView = backendValue.split(',');
            } else if (code === this.liquidPixelSeed && backendValue) {
                this.productAttribute.liquidPixelSeed = backendValue;
            }
        });
    }

    private setProductOptions(
        product: IBundleSelectionData | IConfigurableSelectionData | IDummySelectionData,
        isColorize: boolean,
        index: number,
    ) {
        let skuDelimiter = isColorize ? '-' : '|';
        const colorizeDelimiter = '|';
        const color = LiquidPixelBase.extractColorCode(product.color);
        const sku = color ? product.sku.split(color)[0] || product.sku : product.sku;

        if (this.isBundleProduct()) {
            if (index === 0) {
                skuDelimiter = '';
            }
            this.config.bundleID = this.config.bundleID ? `${this.config.bundleID}${skuDelimiter}${sku}` : `${sku}`;
        } else {
            this.config.prodID = this.config.prodID ? `${this.config.prodID}${skuDelimiter}${sku}` : `${sku}`;
        }

        if (isColorize && product.type_id !== EProductType.empty) {
            this.config.colorize = this.config.colorize ? `${this.config.colorize}${colorizeDelimiter}${color}` : color;
        }
    }

    private setProductColor(color: string) {
        const colorDelimiter = '|';
        this.config.prodColor = this.config.prodColor ? `${this.config.prodColor}${colorDelimiter}${color}` : color;
    }

    private setSelectedProducts() {
        const { productConfig } = this.props;
        if (this.isPossibleToGenerateUrl()) {
            if (this.isBundleProduct()) {
                const bundleProductsSelections: IBundleSelectionData[] = productConfig.simpleProduct.bundledProducts
                    .flatMap((bundleOption: IBundleOptionData) => bundleOption.selections);

                const selectedProducts: (IBundleSelectionData)[][] = Object.entries(productConfig.selections).reduce(
                    (acc: (IBundleSelectionData)[][], [id, selection]) => {
                        const selectionOptions = Array.isArray(selection) ? selection : [selection];
                        const selectedOptionProducts = selectionOptions.map(
                            (selectionOption: string) => bundleProductsSelections.find(
                                selection => selection.selection_id === selectionOption,
                            ),
                        ).filter((product): product is IBundleSelectionData => product !== undefined);

                        if (selectedOptionProducts !== undefined && selectedOptionProducts.length > 0) {
                            acc[Number(id)] = selectedOptionProducts;
                        }
                        return acc;
                    },
                    [],
                );
                this.selectedProducts = this.getWithRemainingSelectionProducts(selectedProducts);
            } else if (this.isConfigurableProduct()) {
                const getSelected = (config, id) => getOptionValue(
                    x => x.id === id,
                )(config.selections, config.product);

                const selectedProducts: (IConfigurableSelectionData)[][] = productConfig.attributes?.reduce(
                    (acc: (IConfigurableSelectionData)[][], att) => {
                        if (att.type === 'VisualSwatches') {
                            const selected = getSelected(productConfig, att.id);
                            if (selected) {
                                acc[att.id] = [{
                                    type_id: productConfig?.simpleProduct.type,
                                    sku: productConfig?.simpleProduct?.sku,
                                    color: selected,
                                }];
                            }
                        }
                        return acc;
                    },
                    [],
                ) ?? [];

                if (selectedProducts && selectedProducts.length > 0) {
                    this.selectedProducts = selectedProducts;
                }
            }
        }
    }

    private setProductsSelectionsColorize() {
        const { productConfig } = this.props;
        if (this.isPossibleToGenerateUrl() && this.isBundleProduct()) {
            let isExist = false;
            productConfig.simpleProduct.bundledProducts.forEach((bundleOption: IBundleOptionData) => {
                isExist = !!bundleOption.selections.find(selection => isVirtualDoubleProduct(selection));
                this.productSelectionColorize[Number(bundleOption.id)] = {
                    isColorize: isExist,
                };
            });
        }
    }

    private isBundleProduct(): boolean {
        return this.productType === 'bundle';
    }

    private isConfigurableProduct(): boolean {
        return this.productType === 'configurable';
    }

    /**
     * This is to pass not selected option product sku as dummy/empty product
     *
     * Adds dummy product to selectedProducts - when only product is selected and color is not
     * Adds empty product to selectedProducts - when no product option is selected
     *
     * @param selectedProducts
     * @private
     */
    private getWithRemainingSelectionProducts(selectedProducts: ISelectedProducts): ISelectedProducts {
        const { productConfig } = this.props;
        const withRemainingSelectionProducts = selectedProducts;
        if (this.isBundleProduct()) {
            productConfig.simpleProduct.bundledProducts.forEach((bundleOption: IBundleOptionData) => {
                const partiallySelectedProductName = productConfig?.selectedProductNames
                    ? productConfig.selectedProductNames[Number(bundleOption.id)] || ''
                    : '';
                let partiallySelectedProduct = bundleOption.selections.find(
                    selection => selection.selection_title === partiallySelectedProductName,
                );
                const missingProducts: (IBundleSelectionData | IConfigurableSelectionData | IDummySelectionData)[] = [];
                if (!Object.keys(selectedProducts).includes(String(bundleOption.id))) {
                    if (partiallySelectedProduct) {
                        let shouldAddTwice = false;
                        if (isVirtualDoubleProduct(partiallySelectedProduct)) {
                            shouldAddTwice = true;
                            const fetchDoubleSku = partiallySelectedProduct.sku.split('-').pop();
                            if (fetchDoubleSku) {
                                partiallySelectedProduct = bundleOption.selections.find(
                                    selection => selection.sku.startsWith(fetchDoubleSku),
                                );
                            }
                        }
                        const color = LiquidPixelBase.extractColorCode(partiallySelectedProduct?.color || '');
                        const sku = color ? partiallySelectedProduct?.sku.split(color)[0] || '' : '';

                        if (sku) {
                            const dummyProduct: IDummySelectionData = {
                                type_id: EProductType.dummy,
                                sku,
                                color: this.neutralColor,
                            };
                            missingProducts.push(dummyProduct);
                            if (shouldAddTwice) {
                                missingProducts.push(dummyProduct);
                            }
                        }
                    } else {
                        // This means none of the option is selected for this bundle product group
                        // thus, we need to add a empty product
                        const dummyProduct: IDummySelectionData = {
                            type_id: EProductType.empty,
                            sku: this.nullSku,
                            color: this.neutralColor,
                        };
                        missingProducts.push(dummyProduct);
                    }
                } else if (partiallySelectedProduct && isVirtualDoubleProduct(partiallySelectedProduct)) {
                    if (selectedProducts[Number(bundleOption.id)] &&
                        selectedProducts[Number(bundleOption.id)].length === 1
                    ) {
                        const userSelects = selectedProducts[Number(bundleOption.id)][0];
                        missingProducts.push(userSelects);
                        const dummyProduct: IDummySelectionData = {
                            type_id: EProductType.dummy,
                            sku: userSelects.sku,
                            color: userSelects.color,
                        };
                        missingProducts.push(dummyProduct);
                    }
                }
                if (missingProducts.length > 0) {
                    withRemainingSelectionProducts[Number(bundleOption.id)] = missingProducts;
                }
            });
        }
        return withRemainingSelectionProducts;
    }
}
