import React from 'react';
import { Router } from '@silkpwa/module/react-component/router';
import { useConfig } from '@silkpwa/module/ecommerce-catalog/config/use-config';
import { ShowLoader } from 'ui/component/show-loader';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { useCart } from '@silkpwa/module/ecommerce-cart/use-cart';
import { hasPunchoutInitialSession } from '@silkpwa/module/store-mode';
import { asyncComponent } from '@silkpwa/module/async-component';
import styles from './styles.css';

const AsyncHomePage = asyncComponent(() => import('ui/page/home-page/home-page'));
const AsyncGlobalNetwork = asyncComponent(() => import('ui/page/global-network/global-network'));
const AsyncProductPage = asyncComponent(() => import('ui/page/product-page/product-page'));
const AsyncCategoryPage = asyncComponent(() => import('ui/page/category-page/category-page'));
const AsyncCartPage = asyncComponent(() => import('ui/page/cart-page'));
const AsyncCheckoutPage = asyncComponent(() => import('ui/page/checkout-page/checkout-page'));
const AsyncSuccessPage = asyncComponent(() => import('ui/page/checkout-page/success-page'));
const AsyncLogInPage = asyncComponent(() => import('ui/page/log-in-page'));
const AsyncSearchPage = asyncComponent(() => import('ui/page/search-page/search-page'));
const AsyncCMSPage = asyncComponent(() => import('ui/page/cms-page/cms-page'));
const AsyncOverrideCategory = asyncComponent(() => import('ui/page/override-category-page/override-category-page'));


interface IExpiryProps {
    expiryMessage: string;
}

// This is coming from content in the CMS, so it is fine.
/* eslint-disable react/no-danger */
export const Expiry: React.FC<IExpiryProps> = ({ expiryMessage }) => (
    <div className={styles.expiryMessage}>
        <div dangerouslySetInnerHTML={{ __html: expiryMessage }} />
    </div>
);
/* eslint-enable */

const Page = () => {
    const t = usePhraseTranslater();
    const config = useConfig();
    const cart = useCart();

    if (config.extension_attributes) {
        const isPunchoutMessageEnabled = config.extension_attributes.punchout_session_message_enabled || false;
        const sessionMessage = config.extension_attributes.punchout_session_message || '';
        const hasSession = cart.hasCustomer || hasPunchoutInitialSession();

        if (isPunchoutMessageEnabled && sessionMessage && !hasSession) {
            return <Expiry expiryMessage={sessionMessage} />;
        }
    }
    return (
        <Router
            homepage={<AsyncHomePage />}
            global={<AsyncGlobalNetwork />}
            product={<AsyncProductPage />}
            category={<AsyncCategoryPage />}
            cart={<AsyncCartPage />}
            checkout={<AsyncCheckoutPage />}
            success={<AsyncSuccessPage />}
            login={<AsyncLogInPage />}
            search={<AsyncSearchPage />}
            cms={<AsyncCMSPage />}
            override_category={<AsyncOverrideCategory />}
            notfound={<p>{t('Not Found...')}</p>}
            loadingFallback={(
                <div className={styles.loader}>
                    <ShowLoader message={t('Loading page')} />
                </div>
    )}
        />
    );
};

export const Content = () => (
    <div className={styles.content}>
        <Page />
    </div>
);
